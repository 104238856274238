<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Expositions" subtitle="Liste des expositions disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une visite'"
        @click="storeExposition"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <trame-uploader
      id="document_fiche_eval_prealable"
      :file_system="'EXPOSITIONS'"
      title="Exportez à partir d'un modèle"
      :data="{ values: { ...dataExport } }"
    ></trame-uploader>
    <div class="mb-3 rounded shadow-sm p-3" v-background-3>
      <h6 class="m-0">Catégorie A/B ou P</h6>
      <div class="mb-2 text-justify pr-md-3">
        <small
          >Le classement en catégorie A ou B permet aux PCRs de consulter le profil du collaborateur et de gérer la
          dosimétrie.</small
        >
      </div>
      <div class="d-flex">
        <div
          class="border border-primary rounded p-3 mr-3 cursor-pointer"
          :class="{ 'text-white bg-primary': collaborateur_categorie_id == 1 }"
          @click="setCategorie(1)"
        >
          A
        </div>
        <div
          class="border border-primary rounded p-3 mr-3 cursor-pointer"
          :class="{ 'text-white bg-primary': collaborateur_categorie_id == 2 }"
          @click="setCategorie(2)"
        >
          B
        </div>
        <div
          class="border border-primary rounded p-3 mr-3 cursor-pointer"
          :class="{ 'text-white bg-primary': collaborateur_categorie_id == 3 }"
          @click="setCategorie(3)"
        >
          P
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="exposition in expositions" :key="exposition.id">
          <expositions-item :exposition="exposition"></expositions-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import TrameUploader from "@/components/application/TrameUploader.vue";
import ExpositionsItem from "@/components/collaborateurs/profil/forms/exposition/ExpositionsItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    ExpositionsItem,
    BaseToolBar,
    TrameUploader,
    BaseButton,
  },
  data() {
    return {
      newExposition: { exposition_collaborateur_id: 0 },
      feedback: {},
      showActives: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _storeExposition: "collaborateurs/createExposition",
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
    }),
    setCategorie: function (i) {
      this.collaborateur_categorie_id = i;
      this.updateCollaborateur();
    },
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    storeExposition: function () {
      this.feedback = {};
      this.loading = true;
      this._storeExposition(this.newExposition)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    contratVersion: function (contrat) {
      // versions du contrat
      const contratVersions = contrat.contrat_version;

      if (!contratVersions) return {};

      // Nombre de versions disponibles
      const nbrVersions = contratVersions.length;
      if (nbrVersions < 1) {
        return {};
      }

      // Version antérieure à la date du jour (sur date d'effet)
      const contratVersionsFiltered = contratVersions.filter((v) => this.$moment(v.date_effet) <= this.$moment());

      // get array length
      const nbrVersionsFiltered = contratVersionsFiltered.length;

      // retour null si aucune version des paramètres du contrat
      if (nbrVersions >= 1 && nbrVersionsFiltered >= 1) return contratVersionsFiltered[nbrVersionsFiltered - 1];
      if (nbrVersions >= 1 && nbrVersionsFiltered === 0) return contratVersions[nbrVersions - 1];
      return {};
    },
  },
  mounted() {
    this.newExposition.exposition_collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.id",
      "collaborateur.collaborateur_nom",
      "collaborateur.collaborateur_prenom",
      "collaborateur.collaborateur_categorie_id",
      "collaborateur.collaborateur_contrats",
    ]),
    ...mapMultiRowFields("collaborateurs", ["expositions"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    dataExport() {
      let expositions = this.expositions;
      let type = "";
      let nature = "";
      let categorie = "";
      let collaborateur = "";
      let contrat = "";
      let societe = "";
      let siret = "";
      let adresse = "";
      let dateDebut = "";
      let dateFin = "";
      let dosePrevue = 0;

      if (!expositions || expositions.length < 1) {
        return [];
      } else {
        expositions = expositions[expositions.length - 1];
      }

      let _contratLength = this.collaborateur_contrats.length;
      let _contrat = {};

      if (_contratLength >= 1) {
        _contrat = this.collaborateur_contrats[_contratLength - 1];
      }

      _contrat = this.contratVersion(_contrat);

      if (
        _contrat.contrat_siret &&
        _contrat.contrat_siret.siret_societe &&
        _contrat.contrat_siret.siret_societe.description !== undefined
      ) {
        societe = _contrat.contrat_siret.siret_societe.description;
      } else {
        societe = "";
      }

      if (_contrat.contrat_siret.siret !== undefined) {
        siret = _contrat.contrat_siret.siret;
      } else {
        siret = "";
      }

      if (_contrat.contrat_siret.siret_adresse !== undefined && _contrat.contrat_siret.siret_adresse) {
        adresse = _contrat.contrat_siret.siret_adresse;
      } else {
        adresse = "";
      }

      if (_contrat.contrat_libelle !== undefined) {
        contrat = _contrat.contrat_libelle;
      } else {
        contrat = "";
      }

      if (expositions.type && expositions.type.description !== undefined) {
        type = expositions.type.description;
      } else {
        type = "";
      }

      if (expositions.type && expositions.type.nature_travaux !== undefined) {
        nature = expositions.type.nature_travaux;
      } else {
        nature = "";
      }

      if (expositions.categorie && expositions.categorie.description !== undefined) {
        categorie = expositions.categorie.description;
      } else {
        categorie = "";
      }

      if (expositions.exposition_dose_prevue) {
        dosePrevue = expositions.exposition_dose_prevue;
      } else {
        dosePrevue = 0;
      }

      dosePrevue;

      if (expositions.exposition_date !== undefined) {
        dateDebut = this.$moment(expositions.exposition_date).format("DD/MM/YYYY");
      } else {
        dateDebut = "";
      }

      if (expositions.exposition_date_validite !== undefined) {
        dateFin = this.$moment(expositions.exposition_date_validite).format("DD/MM/YYYY");
      } else {
        dateFin = "";
      }

      if (this.collaborateur_nom !== undefined && this.collaborateur_prenom !== undefined) {
        collaborateur = this.collaborateur_nom + " " + this.collaborateur_prenom;
      } else {
        collaborateur = "";
      }

      return {
        type: type.normalize("NFC").replace("’", "'"),
        nature: nature.normalize("NFC").replace("’", "'"),
        categorie: categorie.normalize("NFC").replace("’", "'"),
        collaborateur: collaborateur.normalize("NFC").replace("’", "'"),
        contrat: contrat.normalize("NFC").replace("’", "'"),
        societe: societe.normalize("NFC").replace("’", "'"),
        siret: siret.normalize("NFC").replace("’", "'"),
        adresse: adresse.normalize("NFC").replace("’", "'"),
        dateDebut: dateDebut,
        dateFin: dateFin,
        periode: dateDebut + " au " + dateFin,
        metier: contrat.normalize("NFC").replace("’", "'"),
        date: this.$moment().format("dd/MM/YYYY"),
        dose_prevue: dosePrevue,
      };
    },
  },
};
</script>
<style lang="css"></style>
