<template>
  <container-list-extend title="Exposition">
    <template v-slot:title>
      <div
        class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1"
      >
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-success"
          fill="currentColor"
          v-if="exposition.exposition_active"
          v-tooltip="'Exposition active'"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-danger"
          fill="currentColor"
          v-if="!exposition.exposition_active"
          v-tooltip="'Exposition inactive'"
        ></BaseIcon>
        <h6 class="m-0 ml-3" style="line-height:1rem;">
          <description-from-list
            :value="exposition.exposition_type_id"
            :data="data.expositionTypes"
            text="Nouvelle exposition"
          ></description-from-list>
        </h6>
      </div>
      <div
        class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center"
      >
        <small>
          <div v-if="exposition.exposition_date_validite">
            validité :
            {{
              $moment(exposition.exposition_date_validite).format("DD/MM/YYYY")
            }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        @click="updateExposition(exposition)"
        deleteBtn
        @delete="deleteExposition(exposition)"
        :statut="statut"
        :loading="loading"
      >
        <base-form-row row>
          <base-select
            inputText="Exposition"
            v-model.number="exposition.exposition_type_id"
            :options="data.expositionTypes"
          ></base-select>
          <base-select
            inputText="Etat"
            v-model.number="exposition.exposition_categorie_id"
            :options="data.expositionCategories"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="exposition.exposition_date"
            inputText="Date de début"
          ></base-inputDatePicker>
          <base-inputDatePicker
            v-model="exposition.exposition_date_validite"
            inputText="Date de de validité"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="exposition.exposition_commentaire"
            text="Commentaire"
          ></base-input-area>
        </base-form-row>

        <base-form-row row>
          <base-input-number
            v-model="exposition.exposition_dose_prevue"
            inputText="Dose annuelle prévue (mSv)"
            :errors="feedback.exposition_dose_prevue"
            :step="1"
          ></base-input-number>
        </base-form-row>

        <div class>
          <uploader
            :id="'document_exposition_' + exposition.id"
            url="/upload/document"
            file_system="SIRH"
            :file_field="'collaborateur_exposition_' + exposition.id"
            :file_key="$route.params.id"
          ></uploader>
        </div>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import DescriptionFromList from "@/components/bases/DescriptionFromList";
import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";

import BaseFormRow from "@/components/bases/FormRow.vue";

import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import Uploader from "@/components/bases/Uploader.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";

export default {
  name: "ExpositionsItem",
  components: {
    BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputNumber,
    BaseSelect,
    BaseInputDatePicker,
    Uploader,
    DescriptionFromList,
    BaseInputArea
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null
    };
  },
  props: ["exposition"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data"
    })
  },
  methods: {
    ...mapActions({
      _updateExposition: "collaborateurs/updateExposition",
      _deleteExposition: "collaborateurs/deleteExposition"
    }),
    updateExposition: function(exposition) {
      this.feedback = {};
      this.loading = true;
      this._updateExposition(exposition)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteExposition: function(exposition) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteExposition(exposition)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    lib(id, data) {
      const index = data.findIndex(x => x.id === id);
      return data[index].description;
    }
  }
};
</script>
<style lang="css"></style>
